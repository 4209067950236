



























































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class CompareVesselHelp extends Vue {
  tab = null;
}
